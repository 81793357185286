.pl {
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
  
.pl-texts {
width: 65%;
}

.pl-title {
font-size: 50px;
font-weight: 600;
}

.pl-desc {
margin: 30px px;
}

.pl-list {
width: 100%;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
}

@media screen and (max-width: 700px) and (min-width: 480px) {
    .pl-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .pl-list img {
        width: 50%;
        height: 50%;
    }
}

@media screen and (max-width: 480px) {
.pl {
    padding: 10px;
}

.pl-texts {
    width: 100%;
}

.pl-desc {
    display: none;
}

.pl-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
}
  